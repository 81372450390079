import React from "react";
import pk1 from "../../Asset/Image/pk1.png";
import pk2 from "../../Asset/Image/pk2.png";
import pk3 from "../../Asset/Image/pk3.png";
import pk4 from "../../Asset/Image/pkt12.jpg";
import pk5 from "../../Asset/Image/pk5.png";
import pk6 from "../../Asset/Image/pk6.png";
import Card from "react-bootstrap/Card";
const ServicesBody = () => {
  return (
    <section className="mt-5">
      <div className="container pt-4">
        <div className="row ">
          <div className="col-sm-6 col-lg-12 mt-4">
            <Card className="kard">
              <div className="row">
                <div className="col-sm-12 col-lg-6 d-flex ">
                  <img src={pk1} alt="service" className="img-fluid" />
                </div>
                <div className="col-sm-12 col-lg-6 px-4">
                  <h6 className="mt-5">Carpets</h6>
                  <p className="mt-4">
                    "Spot On Flooring Company offers a wide range of premium
                    carpets to elevate your space. Our selection includes
                    luxurious, durable, and stylish carpets to suit your needs.
                    Experience comfort, quality, and beauty underfoot with our
                    exquisite carpet options. Transform your floors with Spot On
                    Flooring's exceptional carpet solutions."
                  </p>
                </div>
              </div>
            </Card>
          </div>

          <div className="col-sm-6 col-lg-12 mt-4">
            <Card className="kard">
              <div className="row">
                <div className="col-sm-12 col-lg-6">
                  <img src={pk2} alt="service" className="img-fluid" />
                </div>
                <div className="col-sm-12 col-lg-6 px-4">
                  <h6 className="mt-5">Carpet Installation</h6>
                  <p className="mt-4">
                    "At Spot On Flooring Company, our expert team specializes in
                    seamless carpet installations. We ensure a hassle-free
                    process from start to finish, delivering precision and
                    quality. Elevate your space with our professional carpet
                    installation services. Experience a seamless and stunning
                    transformation with Spot On Flooring."
                  </p>
                </div>
              </div>
            </Card>
          </div>

          <div className="col-sm-6 col-lg-12 mt-4">
            <Card className="kard">
              <div className="row">
                <div className="col-sm-12 col-lg-6">
                  <img src={pk3} alt="service" className="img-fluid" />
                </div>
                <div className="col-sm-12 col-lg-6 px-4">
                  <h6 className="mt-5">Carpet Repairs</h6>
                  <p className="mt-4">
                    "Spot On Flooring Company offers top-notch carpet repair
                    services. Our skilled technicians expertly mend and restore
                    your carpets to their former glory. Count on us for fast,
                    efficient, and cost-effective carpet repairs. Trust Spot On
                    Flooring to revitalize your flooring investment."
                  </p>
                </div>
              </div>
            </Card>
          </div>

          <div className="col-sm-6 col-lg-12 mt-4">
            <Card className="kard">
              <div className="row">
                <div className="col-sm-12 col-lg-6">
                  <img src={pk4} alt="service" className="img-fluid" />
                </div>
                <div className="col-sm-12 col-lg-6 px-4">
                  <h6 className="mt-5">
                    Pull-Up and Disposal the existing carpets
                  </h6>
                  <p className="mt-4">
                    "At Spot On Flooring Company, we provide hassle-free carpet
                    removal and disposal services. Our professional team
                    efficiently pulls up and disposes of existing carpets,
                    leaving your space ready for a fresh start. Trust us to
                    handle the heavy lifting and responsible disposal, making
                    your renovation or upgrade seamless."
                  </p>
                </div>
              </div>
            </Card>
          </div>

          <div className="col-sm-6 col-lg-12 mt-4">
            <Card className="kard">
              <div className="row">
                <div className="col-sm-12 col-lg-6">
                  <img src={pk5} alt="service" className="img-fluid" />
                </div>
                <div className="col-sm-12 col-lg-6 px-4">
                  <h6 className="mt-5">Flooring</h6>
                  <p className="mt-4">
                    "Spot On Flooring Company provides top-notch flooring
                    solutions for every space. From hardwood to laminate and
                    more, our diverse selection offers quality and style.
                    Elevate your interiors with our expertly installed flooring
                    options. Trust Spot On Flooring for a seamless and stunning
                    upgrade."
                  </p>
                </div>
              </div>
            </Card>
          </div>

          <div className="col-sm-6 col-lg-12 mt-4">
            <Card className="kard">
              <div className="row">
                <div className="col-sm-12 col-lg-6">
                  <img src={pk6} alt="service" className="img-fluid" />
                </div>
                <div className="col-sm-12 col-lg-6 px-4">
                  <h6 className="mt-5">Residential Carpet</h6>
                  <p className="mt-4">
                    "Spot On Flooring offers luxurious residential carpets,
                    elevating the comfort and aesthetics of your home. Our wide
                    selection includes durable and stylish options to suit your
                    space. Experience quality and elegance underfoot with our
                    premium residential carpets. Transform your home with Spot
                    On Flooring's exquisite choices."
                  </p>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesBody;
