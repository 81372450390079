import React from "react";
import "./gallery.css";
const GalHeader = () => {
  return (
    <section className="gallery">
      <div className="container contentgal">
        <div className="galhead">
          <h1 className="text-white">Gallery</h1>
          <p>
            Discover the beauty of our craftsmanship in our flooring gallery,
            <span className="d-block">
              where stunning transformations come to life at Spot On Flooring.
            </span>
          </p>
        </div>
      </div>
    </section>
  );
};

export default GalHeader;
