import React from "react";
import pk5 from "../../Asset/Image/abpkt.png";
import "../../Asset/Style/about.css";
const Apara = () => {
  return (
    <section className="mt-5 pt-5 secondAbout">
      <div className="container">
        <div className="row d-flex align-items-center justify-content-center">
          <div className="col-sm-6 col-lg-6 d-flex justify-content-left align-items-center img-left">
            <div className="pos">
              <div></div>
              <div className="d-flex imgpara">
                <img src={pk5} alt="About Us" className="img-fluid" />

                <h6 className="d-flex">ABOUT</h6>
                <div className="d-flex year">
                  <h3 className="">20+</h3>
                  <p>
                    YEARS OF <span className="d-block">EXPERIENCE</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-6 smabout">
            <div className="beaf mx-2"></div>
            <h3 className="mt-5 fw-bold fs-1">About Us</h3>
            <p className="paraabout">
              At Spot On Flooring, we are dedicated flooring experts with a
              passion for elevating your spaces. With years of experience, we
              offer a diverse range of flooring solutions, from timeless
              hardwood to modern and practical options.
              <span className="d-block mt-3">
                Our unwavering commitment to excellence ensures your
                satisfaction. We pride ourselves on meticulous attention to
                detail and the use of top-quality materials that guarantee
                long-lasting beauty and durability.
              </span>
              <span className="d-block mt-3">
                Whether it's a residential haven or a commercial space, we
                approach every project with precision and dedication. Our
                friendly and knowledgeable team is here to guide you through the
                entire process, from initial consultation to seamless
                installation.
              </span>
              <span className="d-block mt-3">
                We understand that your floors are a vital part of your space,
                adding character and comfort. Spot On Flooring is your trusted
                partner in transforming your vision into reality.
              </span>
              <span className="d-block mt-3">
                Let us help you create an aesthetically pleasing and functional
                environment that reflects your style and exceeds your
                expectations. Discover the difference with Spot On Flooring and
                elevate your floors today
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Apara;
