import React from "react";
import ContactHeader from "../../Component/Contact/ContactHeader";
import Form from "../../Component/Contact/Form";
import Location from "../../Component/Contact/Map";
const Contact = () => {
  return (
    <main>
      <ContactHeader />
      <Form />
      <Location />
    </main>
  );
};

export default Contact;
