import React, { useState } from "react";
import "../Header/navbar-module.css";
import { Link } from "react-router-dom";
import logo from "../../Asset/Image/sim2.png";
import MobileMenu from "./Mobile-menu";
import TopNav from "./TopNav";
import DropDown from "./DropDown";
const menu = [
  {
    title: "Home",
    slug: "/",
  },
  {
    title: "About",
    slug: "/about",
  },
  {
    title: "Services",
    slug: "/services",
  },
  {
    title: "Gallery",
    slug: "/gallery",
  },
  {
    title: "Contact",
    slug: "/contact",
  },
];

const Navbar = () => {
  // window.onscroll = function () {
  //   scrollFunction();
  // };
  // let navStyle = "";
  // function scrollFunction() {
  //   let className = "nav-fixed";
  //   if (
  //     document.body.scrollTop > 90 ||
  //     document.documentElement.scrollTop > 90
  //   ) {
  //     navStyle = `{
  //               position: fixed !important;
  //               background: rgba(255, 255, 255, 0.8) !important;
  //               z-index: 9 !important; top: 0;
  //               backdrop-filter: blur(6px) !important;
  //               left: 0 !important;
  //               right: 0 !important;
  //               animation: slideIn 2s;
  //               transition: transform 1s ease-in-out 0s;
  //               animation-timing-function: ease-out;
  //               animation-duration: .7s;
  //           }`;
  //     let keyFrame = `@keyframes slideIn {
  //               0% {
  //                   opacity: 0;
  //                   top: -30px;
  //               }
  //             100% {
  //                   opacity: 1;
  //                   top: 0;
  //               }
  //             }`;
  //     document.getElementById("nav-style").innerHTML =
  //       "." + className + navStyle + keyFrame;
  //   } else {
  //     navStyle = `{
  //           }`;
  //     document.getElementById("nav-style").innerHTML =
  //       "." + className + navStyle;
  //   }
  // }
  const [dropdown, setDropdown] = useState(false);
  return (
    <>
      <TopNav />
      <header
        className="bg-white position-sticky "
        style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.4)" }}
      >
        {/* add nav-fixed className in header for future work  */}
        <div className="container">
          <div className="header1 container">
            <Link to="/">
              <img src={logo} alt="" className="img-fluid logo" />
            </Link>

            <ul className="nav_menu fw-bold">
              {menu.map((item) => {
                if (item.title === "Services") {
                  return (
                    <li
                      key={item.title}
                      onMouseEnter={() => setDropdown(true)}
                      onMouseLeave={() => setDropdown(false)}
                      className=" h-full py-2"
                    >
                      <Link to={item.slug}>{item.title}</Link>
                      {dropdown && <DropDown />}
                    </li>
                  );
                }
                return (
                  <li key={item.title} className="nav-item mt-2">
                    <Link to={item.slug}>{item.title}</Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="td">
          <MobileMenu menu={menu} />
        </div>
      </header>
    </>
  );
};

export default Navbar;
