import React from "react";
import a1 from "../../Asset/Image/a1.png";
import a2 from "../../Asset/Image/a2.png";
import c3 from "../../Asset/Image/k1.png";
import c6 from "../../Asset/Image/ck6.jpg";
import c1 from "../../Asset/Image/k3.png";
import c2 from "../../Asset/Image/k4.png";
const GalleryCollection = () => {
  return (
    <section className="mt-5 typon">
      <div className="container pt-5">
        <h1 className="text-center fw-bold fs-1 gal">Gallery</h1>
        <div className=" custom">
          <div className="row bg-white">
            <div className="col-sm-6 col-lg-4 imgage ">
              <img src={a1} alt="a1" className="img-fluid mt-4" />
            </div>
            <div className="col-sm-6 col-lg-4 imgage ">
              <img src={a2} alt="a1" className="img-fluid mt-4" />
            </div>
            <div className="col-sm-6 col-lg-4 imgage ">
              <img src={c3} alt="a1" className="img-fluid mt-4" />
            </div>
            <div className="col-sm-6 col-lg-4 imgage">
              <img src={c6} alt="a1" className="img-fluid mt-4" />
            </div>
            <div className="col-sm-6 col-lg-4 imgage">
              <img src={c1} alt="a1" className="img-fluid mt-4" />
            </div>
            <div className="col-sm-6 col-lg-4 imgage">
              <img src={c2} alt="a1" className="img-fluid mt-4" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GalleryCollection;
