import React from "react";
import GalHeader from "../../Component/Gallery/GalHeader";
import SlidGal from "../../Component/Gallery/SlidGal";
import GalleryCollection from "../../Component/Gallery/GalleryCollection";

const Gallery = () => {
  return (
    <main>
      <GalHeader />
      <SlidGal />
      <GalleryCollection />
    </main>
  );
};

export default Gallery;
