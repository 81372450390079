import Carousel from "react-bootstrap/Carousel";
import ExampleCarouselImage from "../../Asset/Image/s1.jpg";
import s2 from "../../Asset/Image/s2.jpg";
import s3 from "../../Asset/Image/s3.jpg";
import "../../Asset/Style/Hero.css";
function IndividualIntervalsExample() {
  return (
    <div>
      <Carousel>
        <Carousel.Item interval={10000}>
          <div className="gradient-overlay">
            <img
              className="d-block w-100"
              src={ExampleCarouselImage}
              alt="First slide"
            />
          </div>
          <div className="tex kd">
            <Carousel.Caption className="first3">
              <h3 className=" fs-1 fw-bold text-uppercase f2">
                Book a call back with our flooring Experts,
                {/* <span className="d-block"> */}
                or book in home free measure and quote.
                {/* </span> */}
              </h3>
              <button className="hide">
                <a
                  href="tel: 61 452 393 213"
                  className="text-decoration-none text-white"
                >
                  Call us: +61 452 393 213
                </a>
              </button>
            </Carousel.Caption>
          </div>
        </Carousel.Item>
        <Carousel.Item interval={10000}>
          <div className="gradient-overlay">
            <img className="d-block w-100" src={s2} alt="First slide" />
          </div>
          <div className="tex mt-4">
            <Carousel.Caption className="first3">
              <h3 className="fs-1 fw-bold text-uppercase f2">
                "Floor Captures Attention at first,
                <span className="d-block"> glance."</span>
              </h3>
              <button className="hide">
                <a
                  href="tel: 61 452 393 213"
                  className="text-decoration-none text-white"
                >
                  Contact us
                </a>
              </button>
            </Carousel.Caption>
          </div>
        </Carousel.Item>
        <Carousel.Item interval={10000}>
          <div className="gradient-overlay">
            <img className="d-block w-100" src={s3} alt="First slide" />
          </div>
          <div className="tex">
            <Carousel.Caption className="first3">
              <h3 className=" fs-1 fw-bold text-uppercase f2">
                "Carpets that define your style"
              </h3>
              <button className="hide">
                <a
                  href="tel: 61 452 393 213"
                  className="text-decoration-none text-white"
                >
                  Contact us
                </a>
              </button>
            </Carousel.Caption>
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

export default IndividualIntervalsExample;
