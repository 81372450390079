import React from "react";
import HeaderAbout from "../../Component/About/HeaderAbout";
import Apara from "../../Component/About/Apara";

const AboutUs = () => {
  return (
    <main>
      <HeaderAbout />
      <Apara />
    </main>
  );
};

export default AboutUs;
