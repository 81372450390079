import React from "react";
import "./services.css";
const ServicesHead = () => {
  return (
    <section className="services">
      <div className="container contentService">
        <div className="sevhea">
          <h1>Our Services</h1>
          <p>
            Our spot-on flooring company specializes in expert installation,
            <span className="d-block">
              refinishing, and repairs for all types of flooring materials Trust
              us for
            </span>
            professional, reliable flooring solutions that enhance the beauty
            and longevity of your floors.
          </p>
        </div>
      </div>
    </section>
  );
};

export default ServicesHead;
