import { slide as Menu } from "react-burger-menu";
import "./index.css";
import { Link } from "react-router-dom";
import { useState } from "react";
const MobileMenu = ({ menu }) => {
  // window.onscroll = function () {
  //   scrollFunction();
  // };
  // let navStyle = "";
  // function scrollFunction() {
  //   let className = "nav-fixed";
  //   if (
  //     document.body.scrollTop > 90 ||
  //     document.documentElement.scrollTop > 90
  //   ) {
  //     navStyle = `{
  //               position: fixed !important;
  //               background: rgba(255, 255, 255, 0.8) !important;
  //               z-index: 9 !important; top: 0;
  //               backdrop-filter: blur(6px) !important;
  //               left: 0 !important;
  //               right: 0 !important;
  //               animation: slideIn 2s;
  //               transition: transform 1s ease-in-out 0s;
  //               animation-timing-function: ease-out;
  //               animation-duration: .7s;
  //           }`;
  //     let keyFrame = `@keyframes slideIn {
  //               0% {
  //                   opacity: 0;
  //                   top: -30px;
  //               }
  //             100% {
  //                   opacity: 1;
  //                   top: 0;
  //               }
  //             }`;
  //     document.getElementById("nav-style").innerHTML =
  //       "." + className + navStyle + keyFrame;
  //   } else {
  //     navStyle = `{
  //           }`;
  //     document.getElementById("nav-style").innerHTML =
  //       "." + className + navStyle;
  //   }
  // }
  const [menuOpen, setMenuOpen] = useState(false);

  const handleStateChange = () => {
    setMenuOpen(!menuOpen);
  };
  const closeMenu = () => {
    setMenuOpen(false);
  };
  return (
    <header>
      <Menu
        disableAutoFocus
        isOpen={menuOpen}
        onOpen={handleStateChange}
        onClose={handleStateChange}
      >
        {menu.map((item) => {
          return (
            <Link
              key={item.slug}
              onClick={() => closeMenu()}
              className="menu-item"
              to={item.slug}
            >
              {item.title}
            </Link>
          );
        })}
      </Menu>
      <div className=" d-flex justify-content-end munu">
        <a
          href="https://www.facebook.com/profile.php?id=61552136364396"
          target="_blank"
          rel="noreferrer"
        >
          <i className="bi bi-facebook text-black px-1"></i>
        </a>
        <a
          href="https://instagram.com/spotonfloor?igshid=MzRlODBiNWFlZA=="
          target="_blank"
          rel="noreferrer"
        >
          <i className="bi bi-instagram text-black px-1"></i>
        </a>
      </div>
    </header>
  );
};

export default MobileMenu;
