import React from "react";
import Carousel from "react-bootstrap/Carousel";
import "./gallery.css";
import pk1 from "../../Asset/Image/pk1.png";
import pk2 from "../../Asset/Image/pk2.png";
import pk3 from "../../Asset/Image/pk3.png";
import pk4 from "../../Asset/Image/pkt12.jpg";
import pk5 from "../../Asset/Image/pk5.png";
import pk6 from "../../Asset/Image/pk6.png";
const SlidGal = () => {
  return (
    <section className="mt-4 typon">
      <div className="container pt-5">
        <h1 className="my-3 pb-1 taken">Latest Project</h1>
        <div className="row d-flex align-items-center">
          <div className="col-sm-6 col-lg-6">
            <Carousel data-bs-theme="dark" className="zoom-carousel">
              <Carousel.Item>
                <div className="gradient-overlay">
                  <img
                    className="d-block w-100 sk"
                    src={pk1}
                    alt="First slide"
                  />
                </div>
                <Carousel.Caption>
                  <h5 className="text-white fw-bold fs-1">Carpets</h5>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <div className="gradient-overlay">
                  <img
                    className="d-block w-100 sk"
                    src={pk2}
                    alt="Second slide"
                  />
                </div>
                <Carousel.Caption>
                  <h5 className="text-white fw-bold fs-1">
                    Carpet Installation
                  </h5>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <div className="gradient-overlay">
                  <img
                    className="d-block w-100 sk"
                    src={pk3}
                    alt="Third slide"
                  />
                </div>
                <Carousel.Caption>
                  <h5 className="text-white fw-bold fs-1">Carpet Repairs</h5>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <div className="gradient-overlay">
                  <img
                    className="d-block w-100 sk"
                    src={pk4}
                    alt="First slide"
                  />
                </div>
                <Carousel.Caption>
                  <h5 className="text-white fw-bold fs-2">
                    Pull-Up and Disposal the existing carpets
                  </h5>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <div className="gradient-overlay">
                  <img
                    className="d-block w-100 sk"
                    src={pk5}
                    alt="First slide"
                  />
                </div>
                <Carousel.Caption>
                  <h5 className="text-white fw-bold fs-1">Flooring</h5>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <div className="gradient-overlay">
                  <img
                    className="d-block w-100 sk"
                    src={pk6}
                    alt="First slide"
                  />
                </div>
                <Carousel.Caption>
                  <h5 className="text-white fw-bold fs-1">
                    Residential Carpet
                  </h5>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </div>
          <div className="col-sm-6 col-lg-6 mt-3">
            <h3>Gallery Section related to work</h3>
            <p>
              The gallery at Spot On Flooring Company is a captivating showcase
              of our exceptional flooring solutions. Step into a world of
              elegance and innovation, where every square inch of our flooring
              products is on display in a harmonious blend of colors and
              textures. Our gallery is a testament to our commitment to quality,
              featuring a stunning array of hardwood, laminate, vinyl, and tile
              options. Explore the rich warmth of our hardwood collection, the
              durability of our laminate offerings, and the versatility of our
              vinyl and tile designs. Each section of our gallery is
              meticulously curated to inspire your creativity and help you
              envision the perfect flooring for your space. Whether you seek
              classic charm or contemporary flair, our gallery is a source of
              inspiration that will guide you toward making the right flooring
              choice for your home or business. Visit us today to experience the
              Spot On Flooring Company difference firsthand.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SlidGal;
