import React from "react";
import "../../Asset/Style/contact.css";
const ContactHeader = () => {
  return (
    <section className="contact">
      <div className="container conCon">
        <div className="conhea">
          <h1>Contact Us</h1>
          <p className="text-white fw-bold">
            Please don't hesitate to get in touch with us for more information
            <span className="d-block">
              about our residental flooring services. Contact us on{" "}
              <strong>+61 452 393 213 </strong> or {""}
              <strong>gudaal18@gmail.com</strong>
            </span>
          </p>
        </div>
      </div>
    </section>
  );
};

export default ContactHeader;
