import React from "react";
import "../../Asset/Style/about.css";
const HeaderAbout = () => {
  return (
    <section className="about">
      <div className="container contabout">
        <div className="abohea">
          <h1 className="text-white fw-bold">About Us</h1>
          <p className="text-white">
            "Spot & Flooring is your trusted source for top-quality flooring
            <span className="d-block">
              solutions. Our skilled team combines expertise and affordability
              to
            </span>
            transform your spaces. With a commitment to excellence, we enhance
            <span className="d-block">
              homes and offices with beautiful, durable floors."
            </span>
          </p>
        </div>
      </div>
    </section>
  );
};

export default HeaderAbout;
