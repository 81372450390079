import React from "react";
import { Link } from "react-router-dom";
import "./Top.css";
const serviceDropdown = [
  {
    id: 1,
    title: "Carpets",
    slug: "/services",
    ck: "tt",
  },
  {
    id: 2,
    title: "Carpet Installation",
    slug: "/services",
  },
  {
    id: 3,
    title: "Carpet Repairs",
    slug: "/services",
  },
  {
    id: 4,
    title: "Pull-Up and Disposal",
    slug: "/services",
  },
  {
    id: 5,
    title: "Flooring",
    slug: "/services",
  },
  {
    id: 6,
    title: "Residential Carpet",
    slug: "/services",
  },
];
const DropDown = () => {
  return (
    <div className="tmd">
      <ul className="services-submenu">
        {serviceDropdown.map((item) => {
          return (
            <li key={item.id} className="ttd">
              <Link to="/services" className={`submenu-item ${item.ck}`}>
                {item.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default DropDown;
