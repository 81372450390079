import React from "react";
import ServicesHead from "../../Component/Services/ServicesHead";
import ServicesBody from "../../Component/Services/ServicesBody";

const Services = () => {
  return (
    <main>
      <ServicesHead />
      <ServicesBody />
    </main>
  );
};

export default Services;
