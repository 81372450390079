import React from "react";
import Card from "react-bootstrap/Card";
import "../../Asset/Style/Extra.css";
const Map = () => {
  return (
    <section className="location">
      <div className="container">
        <h1 className="text-center fw-bold mb-4">Location</h1>
        <Card className="loc">
          <div className="row d-flex align-items-center">
            <div className="col-sm-6 col-lg-4 px-4 cc">
              <h3>Spot On Flooring</h3>
              <div className="d-flex align-items-center">
                <div className="mb-3 px-2 fs-5">
                  <i className="bi bi-calendar3"></i>
                </div>
                <div>
                  <p>
                    Mon-Sat:
                    <span className="d-block">6 Am To 830 Pm</span>
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div className="mb-3 px-2 fs-5">
                  <i className="bi bi-geo-alt-fill"></i>
                </div>
                <div>
                  <p>Melbourne, Victoria</p>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div className="mb-3 px-2 fs-5">
                  <i className="bi bi-telephone-fill"></i>
                </div>
                <div>
                  <p>
                    +61 452 393 213
                    <span className="d-block">Phone Number</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-8">
              <div style={{ width: "100%" }}>
                <iframe
                  width="100%"
                  height="400"
                  frameBorder="0"
                  scrolling="no"
                  marginHeight="0"
                  marginWidth="0"
                  src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=Melbourne, victoria&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                  title="Spot On Flooring"
                >
                  <a href="https://connectionsgame.org/">Population mapping</a>
                </iframe>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </section>
  );
};

export default Map;
